// APP JS
import AOS from "aos";
import * as fitty from "fitty/dist/fitty.min.js";

AOS.init({
  // Global settings:
  disable: false,
  // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded",
  // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init",
  // class applied after initialization
  animatedClassName: "aos-animate",
  // class applied on animation
  useClassNames: false,
  // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false,
  // disables automatic mutations' detections (advanced)
  debounceDelay: 50,
  // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99,
  // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120,
  // offset (in px) from the original trigger point
  delay: 0,
  // values from 0 to 3000, with step 50ms
  duration: 1000,
  // values from 0 to 3000, with step 50ms
  easing: "ease-out-back",
  // default easing for AOS animations
  once: false,
  // whether animation should happen only once - while scrolling down
  mirror: false,
  // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,
      lazyLoad: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateOut: "fadeOut",
      animateIn: "fadeIn",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider:not(.owl-clients-slider)").owlCarousel({
      items: 1,
      margin: 15,
      loop: true,
      lazyLoad: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 1,
        },
        768: {
          items: 2,
        },
        992: {
          items: 2,
        },
        1200: {
          items: 2,
        },
        1400: {
          items: 2,
          margin: 80,
        },
      },
    });

    // owl-clients-slider
    $(".owl-clients-slider").owlCarousel({
      items: 1,
      margin: 30,
      loop: true,
      lazyLoad: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 2500,

      // speed
      smartSpeed: 2500,
      slideTransition: "linear",

      // navigation
      nav: false,
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 2,
        },
        576: {
          items: 3,
        },
        768: {
          items: 4,
        },
        992: {
          items: 4,
        },
        1200: {
          items: 5,
        },
        1400: {
          items: 6,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // fitty
    fitty("h2", {
      minSize: 48,
      maxSize: 70,
    });

    // menu-overlay
    $(".menu-overlay").on("show.bs.collapse", function () {
      $(this).addClass("show");
    });

    // sticky
    //require('../../vendor/w3media/framework/assets/js/V2/body-sticky-class');
    $(window).scroll(function () {
      var windowWidth = $(window).width();
      var $el = $("body").add(".header");
      if (windowWidth < 0) {
        $el.removeClass("sticky");
        return;
      }

      if ($(window).scrollTop() > 0) {
        $el.addClass("sticky");
      } else {
        $el.removeClass("sticky");
      }
    });

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);

// news-ticker 
let newsListItems = document.querySelectorAll("header div.header-news ul li");
// start by adding the classslist active to the first item and after that change the active class to the next item every 5 seconds infinitely
if(newsListItems && newsListItems.length > 1){
let i = 0;
newsListItems[i].classList.add("active");
setInterval(() => {
  newsListItems[i].classList.remove("active");
  i = i + 1 < newsListItems.length ? i + 1 : 0;
  newsListItems[i].classList.add("active");
}, 7500);
}
else if(newsListItems && newsListItems.length === 1){
  newsListItems[0].classList.add("active");
}
